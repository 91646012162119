import React from 'react'
import GoogleMapReact from 'google-map-react';
import MapWrapper from "./map.style";

const Marker = ({ text }) =>
  <MapWrapper>
    <div className='map-pin' />
    <div className='map-pin-label-outline'>{text}</div>
    <div className='map-pin-label'>{text}</div>
  </MapWrapper>;

const handleApiLoaded = (map, maps) => {
  // use map and maps objects
};

const SimpleMap = ({ title, marginBottom }) => {
  const defaultProps = {
    center: {
      lat: 1.310195012867738,
      lng: 103.90167577493625
    },
    zoom: 17
  };

  return (
    <div style={{ height: '500px', width: '100%', backgroundColor: '#D6DBDF', marginBottom: marginBottom || 0 }}>
      {title &&
        <div style={{ backgroundColor: '#718096', color: '#FFFFFF', width: '100%', padding: '10px 20px 10px 20px' }}>
          {title}
        </div>
      }
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyD5UO4THwZtJ02fx0DihrGKaJb1phNnw40" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        <Marker
          text='Cyder HQ'
          lat={1.310195012867738}
          lng={103.90167577493625}
        />
      </GoogleMapReact>
    </div>
  );
}

export default SimpleMap;