import React, { useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import ReCAPTCHA from "react-google-recaptcha";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";

import { Container, Row, Col } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import { SimpleMap } from "reusecore/Map";

import { postContactToKafka, verifyRecaptcha } from '../../api/Contact';

import ContactWrapper from "./contact.style";

const Contact = () => {
  var showdown = require('showdown'), converter = new showdown.Converter();
  const recaptchaRef = React.createRef();
  const defaultForm = {
    name: "",
    email: "",
    phone: "",
    company: "",
    subject: "",
    message: "",
    submitMessage: "",
    recaptchaValue: ""
  };
  const [form, setForm] = useState(defaultForm);
  const [msg, setMsg] = useState("");

  const onFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setForm({ ...form, [name]: value });
  }

  const onRecaptchaChange = (value) => {
    setForm({
      ...form,
      recaptchaValue: value
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const verifyRecaptchaValue = {}; //await verifyRecaptcha(form.recaptchaValue);
    const { result } = verifyRecaptchaValue || {};
    const { success } = result || {};
    const verifyError = result ? result["error-codes"] : null;

    // if (success) {
    if (form.recaptchaValue) {
      recaptchaRef.current.reset();
      const contact = {
        name: form.name,
        email: form.email,
        phone: form.phone,
        company: form.company,
        subject: form.subject,
        message: form.message,
      };

      let response = await postContactToKafka(contact);

      if (response && !response.error) {
        setMsg("Message has been sent successfully to Team.");
        setForm({ ...form, recaptchaValue: "" });
      } else {
        setMsg(response.error.message);
      }
    } else {
      if (!form.recaptchaValue){
        setMsg("Please complete reCAPTCHA first.");
      } else if (verifyError) {
        setMsg(`Error in verifying reCAPTCHA: ${verifyError[0]}`);
      } else {
        setMsg("Error in verifying reCAPTCHA.");
      }
    }
  }

  return (
    <StaticQuery
      query={graphql`  
        query ContactQuery {
          allStrapiPage(filter: {name: {eq: "getInTouch"}}) {
              edges {
                node {
                  id
                  name
                  body
                  fields {
                      logoUrl
                      bodyHtml
                  }
                  strapiId
                  featuredimage {
                      name
                  }
                }
              }
            }
            site {
              siteMetadata { 
                apiUrl
              }
            }
          }
      `}
      render={data => {
        return (
          <ContactWrapper id="contact">
            <SectionTitle className="section-title" UniWidth="55%">
              {/* <h2>
                <span>Ready to grow your business?</span>
              </h2>
              <h4>Let us show you how we can help with your next project.</h4> */}
              <h2>
                <span>Get in Touch</span>
              </h2>
            </SectionTitle>
            <Container>
              <Row className="contact-body">
                <Col lg={11}>
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(data.allStrapiPage.edges[0].node.body) }} />
                </Col>
              </Row>
              <Row className="contact-details-container">
                <Col sm={12} md={4} className="contact-details-wrapper">
                  <a href="https://maps.google.com/?q=Spaces,Singapore,292 Joo Chiat Road,#03-03" target="_blank">
                    <FaMapMarkerAlt className="contact-icon" />
                    <p>Spaces @292 Joo Chiat Road, #03-03</p>
                    <p>Singapore 427544</p>
                  </a>
                </Col>
                <Col sm={12} md={4} className="contact-details-wrapper">
                  <a href="tel:+6562666013">
                    <FaPhone className="contact-icon" />
                    <p>+65 6266 6013</p>
                  </a>
                </Col>
                <Col sm={12} md={4} className="contact-details-wrapper">
                  <a href="mailto:enquiry@cyder.com.sg">
                    <FaEnvelope className="contact-icon" />
                    <p>enquiry@cyder.com.sg</p>
                  </a>
                </Col>
              </Row>
              <SimpleMap title="Cyder SG Map" marginBottom={160} />
              <SectionTitle className="section-title" UniWidth="55%">
                <h2>
                  <span>Send us a message</span>
                </h2>
                <h4>Tell us about your challenges and we'll be in touch.</h4>
              </SectionTitle>
              <form name="contactform" onSubmit={handleSubmit}>
                <Row className="full-width-row">
                  <Col sm={12} md={6}>
                    <input type="text" name="name" placeholder="Name" onChange={e => onFormChange(e)} required />
                    <input type="email" name="email" placeholder="Email" onChange={e => onFormChange(e)} required />
                    <input type="text" name="phone" placeholder="Phone" onChange={e => onFormChange(e)} required />
                    <input type="text" name="company" placeholder="Company Name" onChange={e => onFormChange(e)} />
                  </Col>
                  <Col sm={12} md={6} >
                    <input type="text" name="subject" placeholder="Subject" onChange={e => onFormChange(e)} required />
                    <textarea name="message" placeholder="Message" onChange={e => onFormChange(e)} required />
                  </Col>
                </Row>
                <Row className="full-width-row">
                  <Col xs={12} className="form-captcha-wrapper">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lf9BiAaAAAAAEcTD4ogLP_EUr7M4Prd1RriBNc4"
                      onChange={onRecaptchaChange}
                    />
                  </Col>
                  <Col xs={12} className="form-button-wrapper">
                    <Button type="submit">Send</Button>
                  </Col>
                  <Col xs={12}>
                    {msg}
                  </Col>
                </Row>
              </form>
            </Container>
          </ContactWrapper>
        )
      }}

    />
  );
};

export default Contact;
