import React from "react";
import { Container, Row, Col } from "reusecore/Layout";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";

import FooterWrapper from "./footer.style";

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <Row Hcenter={true} className="contact-details-container">
          <Col sm={12} md={4} className="contact-details-wrapper">
            <a href="https://maps.google.com/?q=Spaces,Singapore,292 Joo Chiat Road,#03-03" target="_blank">
              <FaMapMarkerAlt className="contact-icon" />
              <p>Spaces @292 Joo Chiat Road, #03-03</p>
              <p>Singapore 427544</p>
            </a>
          </Col>
          <Col sm={12} md={4} className="contact-details-wrapper">
            <a href="tel:+6562666013">
              <FaPhone className="contact-icon" />
              <p>+65 6266 6013</p>
            </a>
          </Col>
          <Col sm={12} md={4} className="contact-details-wrapper">
            <a href="mailto:enquiry@cyder.com.sg">
              <FaEnvelope className="contact-icon" />
              <p>enquiry@cyder.com.sg</p>
            </a>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
