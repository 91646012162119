import styled from 'styled-components';

const ContactWrapper = styled.section`
    padding: 120px 0 200px 0;
    overflow: hidden;
    align-items: center;
    button, a{
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    form{
        text-align: center;
        text-align: center;
        background: #fbfbfb;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 80px;
        border-radius: 10px;
    }
    input, textarea{
        font-family: inherit;
        display: block;
        padding: 15px;
        width: 100%;
        font-size: 16px;
        border-radius: 5px;
        margin: 0 auto 15px auto;
        box-shadow: none;
        transition: 450ms all;
        border: 1px solid transparent;
        &:hover,&:focus{
            border-color: rgba(251,123,129, 1);
        }
    }
    input{
        height: 60px;
    }
    textarea{
        height: 210px;
    }
    button{
        transition: 450ms all;
        background: #FFEBEC;
        color: #FB7B81;
        font-size: 16px;
        height: 58px;
        &:hover{
            background: #FB7B81;
            color: #fff;
        }
        &:focus{
            background: #FB7B81;
            color: #fff;
        }
    }
    a{
        color: #7A848E;
        transition: 250ms all;
        &:hover{
            color: #FB7B81;
        }
    }
    .section-title{
        h2{
            margin-bottom: 60px;
        }
        h4{
            font-weight: 400;
            margin-bottom: 100px;
        }
    }
    .contact-body{
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 90px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }
    .contact-details-container{
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 90px;
        p{
            margin-bottom: 5px;
        }
    }
    .contact-details-wrapper{
        margin-bottom: 40px;
    }
    .contact-icon{
        font-size: 35px;
        color: #FB7B81;
        margin-bottom: 10px;
    }
    .full-width-row{
        display: flex;
        flex: 1;
        width: 100%;
        margin: 0px;
    }
    .form-button-wrapper, .form-captcha-wrapper{
        display: flex;
        justify-content: flex-end;
    }
    .form-button-wrapper{
        margin: 0px;
        margin: 0 0 10px 0;
    }
    .form-captcha-wrapper{
        justify-content: flex-end;
        margin: 0 0 15px 0;
    }
    @media only screen and (max-width: 912px) {
        padding: 120px 0 80px 0;
        form{
            padding: 50px;
        }

    }
    @media only screen and (max-width: 568px) {
        form{
            padding: 30px;
            display: block;
        }
    }
    @media only screen and (max-width: 480px) {
        form{
            padding: 30px 10px;
        }
    }
    
`

export default ContactWrapper;