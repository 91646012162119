import styled from 'styled-components';

const MapWrapper = styled.div`
    font-family: 'Poppins', sans-serif;
    .map-pin {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        background-color: rgb(219, 5, 5);
        border: 2px solid #fff;
        border-radius: 50% 50% 50% 0;
        user-select: none;
        transform: translate(-50%, -100%) rotate(-45deg);
    }
    .map-pin:after {
        content: "";
        width: 14px;
        height: 14px;
        background: rgb(148, 27, 37);
        position: absolute;
        border-radius: 50%;
        margin: 6px 0 0 6px;
        transform: rotate(45deg);
    }
    .map-pin-label-outline {
        width: 100px;
        text-align: center;
        position: absolute;
        top: 6px;
        right: -50px;
        font-weight: 500;
        font-size: 14px;
        color: white;
        -moz-text-fill-color: white;
        -webkit-text-fill-color: white;
        -moz-text-stroke-color: white;
        -webkit-text-stroke-color: white;
        -moz-text-stroke-width: 2px;  
        -webkit-text-stroke-width: 2px;
    }
    .map-pin-label {
        width: 100px;
        text-align: center;
        position: absolute;
        top: 6px;
        right: -50px;
        font-weight: 500;
        font-size: 14px;
        color: rgb(148, 27, 37);
    }
`

export default MapWrapper;