import { apihost } from '../data/global';

export const postContactToKafka = async contact => {
    let url = apihost + '/api/contacts/postContactToKafka';
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(contact),
    })
    .then(function (response) {
        return response.json();
    })
    .then(function (myJson) {
        return myJson;
    })
    .catch(error => {
        console.error('From API', error);
    });
}

export const verifyRecaptcha = async value => {
    let url = apihost + '/api/contacts/verifyRecaptcha';
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ value }),
    })
    .then(function (response) {
        return response.json();
    })
    .then(function (myJson) {
        return myJson;
    })
    .catch(error => {
        console.error('From API', error);
    });
}